import React, { ReactElement } from "react";
import AccountingIcon from "../icons/accountingIcon";
import CustomerIcon from "../icons/customerIcon";
import EmployeeIcon from "../icons/employeeIcon";
import HumanErrorsIcon from "../icons/humanErrorsIcon";
import InventoryIcon from "../icons/inventoryIcon";
import LowRateIcon from "../icons/lowRateIcon";
import PaperworkIcon from "../icons/paperworkIcon";
import ReportingIcon from "../icons/reportingIcon";
import StrategizingIcon from "../icons/strategizingIcon";
import colors from "@styles/variables/pointofsalecostVariables.module.scss";

import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Sales Insights",
        description:
            "Gain valuable insights into your sales data to make well-informed business decisions.",
        icon: <ReportingIcon />,
    },
    {
        title: "Customer Management",
        description:
            "Keep track of your customers and use marketing tools to stay in touch with them.",
        icon: <CustomerIcon />,
    },
    {
        title: "Inventory Management",
        description:
            "Simplify inventory and gain insights into your logistics trends.",
        icon: <InventoryIcon />,
    },
    {
        title: "Employee Management",
        description:
            "Efficiently manage schedules and payrolls while making time clocking easier than ever.",
        icon: <EmployeeIcon />,
    },
    {
        title: "Simplified Accounting",
        description:
            "Streamline accounting processes and easily maintain records of thousands of transactions.",
        icon: <AccountingIcon />,
    },
    {
        title: "Eliminate Errors",
        description:
            "Our POS systems have built-in checks to ensure accurate data entry.",
        icon: <HumanErrorsIcon />,
    },
    {
        title: "Strategic Planning",
        description:
            "Achieve your goals by identifying gaps and making new strategies based on data insights.",
        icon: <StrategizingIcon />,
    },
    {
        title: "Reduced Paperwork",
        description:
            "Automatically generate sales and purchase reports and receive instant updates.",
        icon: <PaperworkIcon />,
    },

    {
        title: "Price Comparison",
        description:
            "Fill out the form above to receive quotes on exclusive deals and discounts.",
        icon: <LowRateIcon />,
    },
];

export default function WhyUs(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <FeatureList
                featureList={whyUsList}
                mainTitle={
                    <>
                        Why Choose <b>PointOfSaleCost.com?</b>
                    </>
                }
                classes={{
                    descriptionClasses:
                        "font-light text-sm lg:text-base text-center w-4/5 mx-auto mt-2",
                }}
                colors={{
                    titleColor: colors.lynxScreenBlue,
                    iconColor: colors.lynxScreenBlue,
                }}
            />
            <button
                onClick={() => scrollToTop()}
                style={{ backgroundColor: "rgb(39, 170, 225)" }}
                className={` text-white py-4 sm:px-11 px-4 text-sm sm:text-lg font-semibold rounded-lg block mx-auto mb-20 hover:-translate-y-2 hover:transition-all transition-all`}
            >
                Get My Free POS Quote Now
            </button>
        </>
    );
}
